import React from "react"

import { useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { DEVICES_WITH_CUSTOM_BUTTON } from "../../constants"
import {
  AUTHENTICATION_TYPE_OPTIONS,
  CUSTOM_BUTTON_ACTION_TYPE_OPTIONS,
  REPLACEABLE_BUTTON_OPTIONS,
  REPLACEABLE_DEVICE_6_PRO_BUTTON_OPTIONS,
  SETTINGS_GROUPS,
  SettingsGroups,
} from "../constants"
import { EffectiveSettingsLocation } from "../types"
import SettingsGroup, { LabelWithToolTip } from "./index"

import { DEVICE_TYPES } from "../../../../../redux/api/devices/constants"
import { RoomDeviceResponse } from "../../../../../redux/api/devices/types"

import { Input } from "../../../../../components/basic/Input"
import MultiToggle from "../../../../../components/basic/MultiToggle"
import { Select } from "../../../../../components/basic/Select"
import Field from "../../../../../components/Field"

type Props = {
  checked: boolean
  disabled: boolean
  onToggleSettingsGroup: (
    settingsGroup: SettingsGroups,
    checked: boolean,
  ) => void
  effectiveSettingsLocation?: EffectiveSettingsLocation
  onLocationClick?: (location: EffectiveSettingsLocation) => void
  isUnavailableWithCurrentPlan?: boolean
  selectedDevice?: RoomDeviceResponse
}

const CustomButtonGroup = ({
  checked,
  disabled,
  onToggleSettingsGroup,
  effectiveSettingsLocation,
  onLocationClick,
  isUnavailableWithCurrentPlan,
  selectedDevice,
}: Props) => {
  const { t } = useTranslation()
  const { control, watch } = useFormContext()

  const actionTypeSelected = !!watch("custom_button.action")

  const isQRActionType =
    watch("custom_button.action")?.value ===
    CUSTOM_BUTTON_ACTION_TYPE_OPTIONS[0].value

  const authenticationEnabled = !!watch(
    "custom_button.payload.authentication.type",
  )

  const doesDeviceSupportCustomButton = DEVICES_WITH_CUSTOM_BUTTON.includes(
    selectedDevice?.type ?? "",
  )

  const isGroupTooltipVisible =
    isUnavailableWithCurrentPlan || !doesDeviceSupportCustomButton
  const groupTooltipText = isUnavailableWithCurrentPlan
    ? t("desktop.settings.rooms.device_settings.form.subscription_unavailable")
    : !doesDeviceSupportCustomButton
      ? t(
          "desktop.settings.rooms.device_settings.form.custom_button.unsupported_device",
        )
      : undefined

  return (
    <SettingsGroup
      label={t(
        "desktop.settings.rooms.device_settings.form.custom_button.title",
      )}
      checked={checked}
      onChange={(checked) =>
        onToggleSettingsGroup(SETTINGS_GROUPS.CUSTOM_BUTTON_GROUP, checked)
      }
      disabled={disabled}
      effectiveSettingsLocation={effectiveSettingsLocation}
      onLocationClick={onLocationClick}
      id={SETTINGS_GROUPS.CUSTOM_BUTTON_GROUP}
      showTooltip={isGroupTooltipVisible}
      tooltipText={groupTooltipText}
    >
      <div className="FieldWrapper Subtext">
        {t("desktop.settings.rooms.device_settings.form.custom_button.intro")}
      </div>
      <div className="field-width-50">
        <Field
          control={control}
          name="custom_button.action"
          label={<LabelWithToolTip group="custom_button" name="action_type" />}
        >
          {(props) => (
            <Select
              {...props}
              options={CUSTOM_BUTTON_ACTION_TYPE_OPTIONS}
              disabled={disabled}
            />
          )}
        </Field>

        <Field
          control={control}
          name="custom_button.replaces"
          label={
            <LabelWithToolTip group="custom_button" name="replaces_button" />
          }
          subText={`(${t(
            "desktop.settings.rooms.device_settings.form.custom_button.replaces_button_device_6_sub_label",
          )})`}
          className="with-sub-text"
        >
          {(props) => (
            <Select
              {...props}
              options={REPLACEABLE_BUTTON_OPTIONS}
              disabled={disabled}
            />
          )}
        </Field>

        <Field
          control={control}
          name="custom_button.replaces_pro"
          label={
            <LabelWithToolTip group="custom_button" name="replaces_button" />
          }
          subText={`(${t(
            "desktop.settings.rooms.device_settings.form.custom_button.replaces_button_device_6pro_sub_label",
          )})`}
          className="with-sub-text"
        >
          {(props) => (
            <Select
              {...props}
              options={REPLACEABLE_DEVICE_6_PRO_BUTTON_OPTIONS}
              disabled={disabled}
            />
          )}
        </Field>
      </div>

      <div className="field-width-50">
        <Field
          control={control}
          name="custom_button.title"
          label={<LabelWithToolTip group="custom_button" name="button" />}
        >
          {(props) => <Input {...props} maxLength={10} disabled={disabled} />}
        </Field>

        {actionTypeSelected && (
          <>
            {isQRActionType ? (
              <Field
                control={control}
                name="custom_button.payload.message"
                label={
                  <LabelWithToolTip group="custom_button" name="qr_code" />
                }
              >
                {(props) => (
                  <Input
                    {...props}
                    placeholder={t(
                      "desktop.settings.rooms.device_settings.form.custom_button.qr_code_placeholder",
                    )}
                    disabled={disabled}
                  />
                )}
              </Field>
            ) : (
              <>
                <Field
                  control={control}
                  name="custom_button.payload.url"
                  label={
                    <LabelWithToolTip
                      group="custom_button"
                      name="webhook_url"
                    />
                  }
                >
                  {(props) => <Input {...props} disabled={disabled} />}
                </Field>

                <Field
                  control={control}
                  name="custom_button.payload.authentication.type"
                  label={
                    <LabelWithToolTip
                      group="custom_button"
                      name="authentication"
                    />
                  }
                >
                  {(props) => (
                    <div className="switch">
                      <MultiToggle
                        options={AUTHENTICATION_TYPE_OPTIONS}
                        {...props}
                      />
                    </div>
                  )}
                </Field>

                {authenticationEnabled && (
                  <>
                    <Field
                      control={control}
                      name="custom_button.payload.authentication.basic.username"
                      label={t(
                        "desktop.settings.rooms.device_settings.form.custom_button.username_label",
                      )}
                    >
                      {(props) => <Input {...props} disabled={disabled} />}
                    </Field>

                    <Field
                      control={control}
                      name="custom_button.payload.authentication.basic.password"
                      label={t(
                        "desktop.settings.rooms.device_settings.form.custom_button.password_label",
                      )}
                    >
                      {(props) => (
                        <Input {...props} disabled={disabled} type="password" />
                      )}
                    </Field>
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </SettingsGroup>
  )
}

export default CustomButtonGroup
