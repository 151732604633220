import React, { createRef, SyntheticEvent, useEffect, useState } from "react"

import { Trans, useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import { analyticsEvent, SupportedEvents } from "../../analytics"
import { FETCH_WITH_NO_LIMIT } from "../../constants"
import { useToast } from "../../hooks/useToast"
import ImportUsersErrorsModal from "../../modals/ImportUsersErrorsModal"
import ImportUsersErrorTable, {
  ImportRowErrors,
} from "../../screens/Settings/UserDirectory/ImportUsersErrorTable"
import Button from "../advanced/Button"
import { FileField } from "../Field/FileField"
import Space from "../Space"
import { useModals } from "@mattjennings/react-modal-stack"

import { api } from "../../redux/api"
import { useAppSelector } from "../../redux/reducers"
import { selectUsers } from "../../redux/users/selectors"
import {
  CSVEntry,
  FetchOptions,
  ImportUserRowErrors,
  UserResponse,
} from "../../redux/users/types"
import {
  batchCreateUsers,
  ENTRIES_PER_PAGE,
  fetchUsers,
  importUsers,
} from "../../redux/users/usersSlice"
import { useActions } from "../../redux/utils"

import exampleCsv from "../../assets/images/example_csv_user_directory.png"
import { ReactComponent as CrossSVG } from "../../assets/images/icons/Cross.svg"
import { ReactComponent as UploadSVG } from "../../assets/images/icons/Upload.svg"

import "./UsersCSVForm.sass"

type Props = {
  isDisabled?: boolean
}

interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget
}

function UsersCSVForm({ isDisabled }: Props) {
  const { closeModal, openModal } = useModals()
  const { t } = useTranslation()
  const { infoToast, errorToast } = useToast()

  const dispatch = useDispatch()

  const actions = useActions({
    batchCreateUsers: (users: CSVEntry[]) => batchCreateUsers(users),
    fetchUsers: (options: FetchOptions) => fetchUsers(options),
    importUsers: (file: File) => importUsers(file),
  })

  const { entries: existingUsers } = useAppSelector(selectUsers)

  const [loading, setLoading] = useState<boolean>(false)
  const [data, setData] = useState<UserResponse[]>([])
  const [existing, setExisting] = useState<CSVEntry[]>([])
  const [rowsWithErrors, setRowsWithErrors] = useState<ImportRowErrors[]>([])

  const fileRef: React.RefObject<HTMLInputElement> = createRef()

  const handleChange = async (e: HTMLInputEvent) => {
    const { target } = e
    const file = target.files && target.files.length > 0 && target.files[0]

    // Reset the event so that multiple files can be uploaded without refreshing the page
    e.target.value = ""
    setData([])
    setExisting([])
    setRowsWithErrors([])
    setLoading(true)

    if (!file) {
      return
    }
    const response = await actions.importUsers(file)

    if (importUsers.rejected.match(response)) {
      errorToast(response.error?.message)
    }

    if (importUsers.fulfilled.match(response)) {
      if (
        "status" in response.payload &&
        response.payload.status === 400 &&
        "errors" in response.payload
      ) {
        const { errors } = response.payload

        const importErrors = mapImportUserRowErrors(errors)

        if (importErrors.length > 0) {
          openModal(ImportUsersErrorsModal, { errors: importErrors })
        }

        setLoading(false)
        errorToast(t("desktop.settings.people.csv_import.upload_failed"))
        return
      }

      if (!("status" in response.payload)) {
        const users = response.payload
        setData(users)
        setExisting(
          existingUsers.filter((existingUser) =>
            users.find((user) => user.email === existingUser.email),
          ),
        )
        infoToast(t("desktop.settings.people.csv_import.upload_successful"))
        setLoading(false)

        dispatch(api.util.invalidateTags([{ type: "Users", id: "LIST" }]))

        const usersRes = await actions.fetchUsers({
          limit: ENTRIES_PER_PAGE,
          offset: 0,
          search: "",
        })
        if (fetchUsers.fulfilled.match(usersRes)) {
          analyticsEvent(SupportedEvents.PEOPLE_ADD, {
            num_users_added: response.payload.length,
            total: usersRes.payload.count,
          })
        }
      }
    }
  }

  const openFileUpload = (e: SyntheticEvent) => {
    e.preventDefault()
    fileRef.current && fileRef.current.click()
  }

  useEffect(() => {
    actions.fetchUsers({
      limit: FETCH_WITH_NO_LIMIT,
      offset: 0,
      search: "",
    })
  }, [actions])

  return (
    <form className="UsersCSVForm ModalForm">
      <div className="title">
        <h1>{t("desktop.settings.people.csv_import.title")}</h1>
      </div>
      <div className="close" onClick={() => closeModal()}>
        <CrossSVG />
      </div>
      <div>
        <FileField
          onChange={handleChange}
          style={{ display: "none" }}
          accept={"text/csv"}
          ref={fileRef}
        />
        <div>
          <Trans components={{ code: <code /> }}>
            {"desktop.settings.people.csv_import.instructions"}
          </Trans>
        </div>
      </div>
      <Space size={0.75} />
      <div>
        {t("desktop.settings.people.csv_import.example_file_content")}:
        <Space size={0.25} />
        <div className="example-file">
          <img src={exampleCsv} alt="Example CSV" />
        </div>
      </div>
      <Space size={0.75} />
      <div className="actions">
        {!loading ? (
          <span className="button-with-hint">
            <Button
              onClick={openFileUpload}
              isDisabled={isDisabled}
              variant="secondary"
            >
              <UploadSVG />
              {t("desktop.settings.people.csv_import.choose_a_file")}
            </Button>
          </span>
        ) : (
          <span>{t("desktop.settings.people.csv_import.uploading")}</span>
        )}
      </div>
      {data.length > 0 && (
        <div>
          <Space size={0.75} />
          <div>{t("desktop.settings.people.csv_import.upload_successful")}</div>
          <Space size={0.25} />
          <div>
            {data.length}{" "}
            {t("desktop.settings.people.csv_import.users_uploaded")}
          </div>
        </div>
      )}
      {existing.length > 0 && (
        <div>
          <Space size={0.75} />
          <div>
            {existing.length}{" "}
            {t("desktop.settings.people.csv_import.users_updated")}:
          </div>
          <Space size={0.25} />
          {existing.map((user) => {
            return (
              <div key={user.email} className="light-text">
                {user.email}
              </div>
            )
          })}
        </div>
      )}
      {rowsWithErrors.length > 0 && (
        <div className="error-list">
          <ImportUsersErrorTable errors={rowsWithErrors} />
        </div>
      )}
    </form>
  )
}

export default UsersCSVForm

const isEmptyObject = (obj: Record<string, unknown>) => {
  return Object.keys(obj).length === 0
}

// map ImportUserRowErrors to ImportRowErrors
const mapImportUserRowErrors = (errors: ImportUserRowErrors[]) => {
  return errors.flatMap((error, i) => {
    if (isEmptyObject(error)) {
      return []
    }
    return Object.entries(error).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: (value as string[]).join(", "),
      }),
      { id: (i + 1).toString() },
    )
  })
}
