import React, { useRef, useState } from "react"

import cn from "classnames"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"

import { useLocalStorage } from "../../../hooks/useLocalStorage"
import { useOnClickOutside } from "../../../hooks/useOnClickOutside"
import {
  DEFAULT_LANGUAGE,
  LANGUAGE_ICONS,
  VM_LANGUAGES,
  WEB_LANGUAGES,
} from "../../../i18n"

import { changeLanguage } from "../../../redux/app/appSlice"
import { useActions } from "../../../redux/utils"

import "./style.sass"

export type LanguageSelectorProps = {
  isVisitorManagement?: boolean
}

const LanguageSelector = ({ isVisitorManagement }: LanguageSelectorProps) => {
  const { i18n } = useTranslation()

  const [showLanguageOptions, setShowLanguageOptions] = useState(false)
  const languageOptionsRef = useRef<HTMLDivElement>(null)

  // Get the stored language from localStorage
  const { value: storedLanguage, onChange: setStoredLanguage } =
    useLocalStorage("app_language", DEFAULT_LANGUAGE)

  // Determine the correct languages array based on isVisitorManagement flag
  const currentLanguageList = isVisitorManagement ? VM_LANGUAGES : WEB_LANGUAGES

  // Ensure the language falls back to default language ('en') if it's not in the current language list
  const language = currentLanguageList.includes(storedLanguage)
    ? storedLanguage
    : DEFAULT_LANGUAGE

  // Get the SVG icon component for the current language
  const LanguageIcon = LANGUAGE_ICONS[language] || null

  useOnClickOutside([languageOptionsRef], () => {
    setShowLanguageOptions(false)
  })

  const actions = useActions({
    changeLanguage: (lang: string) => changeLanguage(lang),
  })

  const options = currentLanguageList
    .filter((lang) => language !== lang)
    .map((lang) => ({
      label: lang,
      value: lang,
    }))

  const onLanguageChange = (value: string) => {
    i18n.changeLanguage(value)
    dayjs.locale(value)
    actions.changeLanguage(value)
    setStoredLanguage(value)
    setShowLanguageOptions(false)
  }

  const languageSelectorClasses = cn("LanguageSelector", {
    "is-visitor-management": isVisitorManagement,
  })

  return (
    <div className={languageSelectorClasses}>
      <div
        onClick={() => {
          setShowLanguageOptions(!showLanguageOptions)
        }}
        className="control"
      >
        <span className="label">
          {LanguageIcon && <LanguageIcon />} {language}
        </span>
      </div>

      {showLanguageOptions && options.length >= 1 && (
        <div className="options" ref={languageOptionsRef}>
          {options.map(({ label, value }, i) => {
            const OptionIcon = LANGUAGE_ICONS[value] || null
            return (
              <div
                className={value === language ? "option active" : "option"}
                key={`option-${i}`}
                onClick={() => onLanguageChange(value)}
              >
                <span className="label">
                  {OptionIcon && <OptionIcon />} {label}
                </span>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default LanguageSelector
