import { useEffect } from "react"

import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { experimentsCompanyURL } from "../../api/urls"
import { PERMISSIONS } from "../../constants"
import { useCheckForPermission } from "../../hooks/useCheckForPermission"
import { useToast } from "../../hooks/useToast"
import { AsyncCheckbox } from "../advanced/AsyncCheckbox"
import { Checkbox } from "../basic/Checkbox"
import Field, { UncontrolledField } from "../Field"
import { setErrors } from "./formUtils"
import PageForm from "./PageFormHook"

import { rooms } from "../../redux/api/rooms"
import {
  fetchExperiments,
  postExperiments,
  updateExperiments,
} from "../../redux/experiments/experimentsSlice"
import { selectRoomsSettings } from "../../redux/experiments/selectors"
import { RoomsSettingsRequest } from "../../redux/experiments/types"
import { useAppSelector } from "../../redux/reducers"
import { useActions } from "../../redux/utils"

import "./RoomsForm.sass"

type FormValues = {
  enabled: boolean
}

function RoomsForm() {
  const { t } = useTranslation()

  const actions = useActions({
    fetchExperiments: () => fetchExperiments(),
  })

  const { roomsSettings } = useAppSelector(selectRoomsSettings)
  const { data } = roomsSettings || {}

  const methods = useForm<FormValues>()

  return (
    <FormProvider {...methods}>
      <PageForm className="RoomsForm" updateMode={true}>
        <UncontrolledField>
          <AsyncCheckbox
            updateMode={!!roomsSettings?.id}
            value={data?.values.enabled}
            label={t("desktop.settings.rooms.form.in_app_room_booking")}
            urlGenerator={() => experimentsCompanyURL(roomsSettings?.id)}
            bodyGenerator={(enabled: boolean) => ({
              data: {
                type: "ROOMS_SETTINGS",
                values: {
                  enabled,
                },
              },
            })}
            refresh={actions.fetchExperiments}
            isSecondary
            successToastMessage={t(
              "desktop.settings.rooms.form.room_booking_settings_updated_toast",
            )}
            description={t(
              "desktop.settings.rooms.form.in_app_room_booking_description",
            )}
          />
        </UncontrolledField>
      </PageForm>
    </FormProvider>
  )
}

export default RoomsForm
