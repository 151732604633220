import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import { DELETE_COMPANY_SURVEY_URL } from "./constants"

import Button from "../../../components/advanced/Button"
import Heading from "../../../components/Heading"
import Space from "../../../components/Space"
import View from "../../../components/View"

import "./DeleteCompanySurvey.sass"

const DeleteCompanySurvey = () => {
  const history = useHistory()
  const { t } = useTranslation()
  return (
    <View className="DeleteCompanySurvey">
      <Space size={4} />
      <Heading>
        {t(
          "desktop.settings.organization.company_details.delete_company.survey.heading",
        )}
      </Heading>

      <p>
        {t(
          "desktop.settings.organization.company_details.delete_company.survey.deletion",
        )}
      </p>
      <p>
        {t(
          "desktop.settings.organization.company_details.delete_company.survey.improve",
        )}
      </p>

      <Space size={0.75} />

      <Button
        onClick={() => {
          window.open(DELETE_COMPANY_SURVEY_URL, "_blank")
        }}
      >
        {t(
          "desktop.settings.organization.company_details.delete_company.survey.take_survey",
        )}
      </Button>

      <Space size={1.5} />

      <p>
        {t(
          "desktop.settings.organization.company_details.delete_company.survey.change_mind",
        )}
      </p>

      <Space size={0.75} />

      <Button variant="link" onClick={() => history.push("/")}>
        {t(
          "desktop.settings.organization.company_details.delete_company.survey.start_over",
        )}
      </Button>
    </View>
  )
}

export default DeleteCompanySurvey
