import React from "react"

import { useTranslation } from "react-i18next"

import GeneralForm from "./GeneralForm"

import { useFetchMeQuery } from "../../../../redux/api/me"

import Loader from "../../../../components/basic/Loader"
import Breadcrumbs from "../../../../components/Breadcrumbs"
import Intro from "../../../../components/Intro"
import Space from "../../../../components/Space"
import View from "../../../../components/View"

import "./styles.sass"

const General = () => {
  const { t } = useTranslation()

  const { data: user, isLoading } = useFetchMeQuery()

  return (
    <View className="General">
      <Breadcrumbs
        depth={1}
        values={[t("desktop.settings.profile.general.title")]}
      />

      <Intro isConstrained>{t("desktop.settings.profile.general.intro")}</Intro>

      <Space size={0.75} />

      {isLoading ? <Loader className="loader" /> : <GeneralForm user={user} />}
    </View>
  )
}

export default General
