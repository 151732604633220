import React, { useEffect, useMemo, useRef } from "react"

import dayjs from "dayjs"
import { ParseKeys } from "i18next"
import { FormProvider, useForm } from "react-hook-form"
import { Trans, useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import { useNavigation } from "../../../../hooks/useNavigation"
import { useToast } from "../../../../hooks/useToast"
import { BILLING_PATHS } from "../constants"
import { PAYMENT_METHOD_OPTIONS, STEPS } from "../Payments/constants"
import PrepaidCodeForm from "../PrepaidCode/PrepaidCodeForm"
import PrepaidCodeInfo from "../PrepaidCode/PrepaidCodeInfo"
import CreditCard from "./CreditCard"

import { api } from "../../../../redux/api"
import { PAYMENT_METHODS } from "../../../../redux/api/billing/constants"
import {
  useFetchPaymentsQuery,
  useLazyFetchPaymentsCreditCardsQuery,
  useSavePaymentCreditCardMutation,
  useSavePaymentPrepaidCodeMutation,
} from "../../../../redux/api/billing/payments"
import { useRedeemPrepaidCodeMutation } from "../../../../redux/api/billing/prepaidCode"
import { ACTIVATION_DATE_OPTIONS } from "../../../../redux/api/billing/prepaidCode/constants"
import { useFetchSubscriptionsQuery } from "../../../../redux/api/billing/subscriptions"
import { PaymentMethod as PaymentMethods } from "../../../../redux/api/billing/types"
import { isApiResponseError, isRejected } from "../../../../redux/api/types"

import Button from "../../../../components/advanced/Button"
import Card from "../../../../components/basic/Card"
import Loader from "../../../../components/basic/Loader"
import { RadioGroup } from "../../../../components/basic/Radio"
import Stepper from "../../../../components/basic/Stepper"
import Breadcrumbs from "../../../../components/Breadcrumbs"
import Field from "../../../../components/Field"
import { setErrors } from "../../../../components/Form/formUtils"
import PageForm from "../../../../components/Form/PageFormHook"
import Space from "../../../../components/Space"
import View from "../../../../components/View"

import "./styles.sass"

const CREDIT_CARDS_POLLING_INTERVAL = 1000
const CREDIT_CARDS_POLLING_TIMEOUT = 10000

const prepaidCodeFormMapping = {
  activated_at: `selectedDate`,
} as const

type PaymentMethodFormValues = {
  type: PaymentMethods
  // for prepaid code
  code: string
  selectedDate: string // 'today' | 'month' | 'custom'
  customDate: Date | null
}
const PaymentMethod = () => {
  const intervalRef = useRef<NodeJS.Timeout | null>(null)
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  const [isPolling, setIsPolling] = React.useState(false)

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { errorToast, infoToast } = useToast()
  const { push, pushToNextOr } = useNavigation()

  const { data: payments, isLoading } = useFetchPaymentsQuery()
  const { data: { results: subscriptions = [] } = {} } =
    useFetchSubscriptionsQuery()
  const [redeemPrepaidCode] = useRedeemPrepaidCodeMutation()
  const [saveCreditCard] = useSavePaymentCreditCardMutation()
  const [savePrepaidCode] = useSavePaymentPrepaidCodeMutation()
  const [fetchCreditCards] = useLazyFetchPaymentsCreditCardsQuery()

  const defaultValues = useMemo(
    () => ({
      type: payments?.type ?? PAYMENT_METHODS.NONE,
      selectedDate: "today",
    }),
    [payments?.type],
  )
  const subscriptionsWithPrepaidCode = subscriptions
    ?.filter((s) => !!s.prepaid_code?.code)
    ?.sort((a, b) => b.subscription_id - a.subscription_id)

  const methods = useForm<PaymentMethodFormValues>({
    defaultValues,
  })

  const {
    control,
    handleSubmit,
    watch,
    setError,
    resetField,
    reset,
    formState: { isSubmitting },
  } = methods

  const selectedType = watch("type")

  const isCreditCardTypeWithoutCreditCard =
    selectedType === PAYMENT_METHODS.CREDIT_CARD && !payments?.credit_card

  const translatedPaymentMethodOptions = PAYMENT_METHOD_OPTIONS.map(
    (option) => ({
      ...option,
      label: t(option.label as ParseKeys),
    }),
  )

  /**
   *  Handlers
   */

  const updatePaymentMethod = async (type: PaymentMethods) => {
    let result

    switch (type) {
      case PAYMENT_METHODS.CREDIT_CARD:
        result = await saveCreditCard()
        break
      case PAYMENT_METHODS.PREPAID_CODE:
        result = await savePrepaidCode()
        break
    }

    if (isRejected(result)) {
      const { error } = result

      if (isApiResponseError(error)) {
        setErrors(error.formError, setError, errorToast)
      }

      return false
    }

    infoToast(
      t("desktop.settings.billing.payment_method.toasts.update_success"),
    )
    return true
  }

  const handleOnUpdate = async ({ type }: PaymentMethodFormValues) => {
    const success = await updatePaymentMethod(type)
    if (!success) {
      return
    }
    if (type === PAYMENT_METHODS.PREPAID_CODE) {
      push(BILLING_PATHS.overview.root)

      return
    }

    pushToNextOr(BILLING_PATHS.overview.root)
  }

  const handleRedeemCode = async () => {
    handleSubmit(async ({ code, selectedDate, customDate }) => {
      const activated_at = (() => {
        switch (selectedDate) {
          case ACTIVATION_DATE_OPTIONS.today:
            return null
          case ACTIVATION_DATE_OPTIONS.beginning_month:
            return dayjs().add(1, "month").startOf("month").toISOString()
          case ACTIVATION_DATE_OPTIONS.custom:
            return dayjs(customDate ?? undefined).toISOString()
          default:
            return null
        }
      })()

      const response = await redeemPrepaidCode({ code, activated_at })

      if (isRejected(response)) {
        const { error } = response

        if (isApiResponseError(error)) {
          setErrors(
            error.formError,
            setError,
            errorToast,
            prepaidCodeFormMapping,
          )
        }
        return
      }

      infoToast(t("desktop.settings.billing.pre_paid_code_card.success_toast"))

      resetField("code")
      resetField("selectedDate", { defaultValue: "today" })
      resetField("customDate")
      if (payments?.type !== "PREPAID_CODE") {
        updatePaymentMethod(PAYMENT_METHODS.PREPAID_CODE)
      }
    })()
  }

  const handleManageCreditCardSuccess = async () => {
    setIsPolling(true)
    const result = await saveCreditCard()
    if (isRejected(result)) {
      const { error } = result

      if (isApiResponseError(error)) {
        setErrors(error.formError, setError, errorToast)
      }

      return
    }
    infoToast(
      t("desktop.settings.billing.payment_method.toasts.update_success"),
    )
    infoToast(
      t(
        `desktop.settings.billing.payment_method.toasts.${
          payments?.credit_card ? "update" : "add"
        }_credit_card_success`,
      ),
    )

    /**
     * the credit card info is saved directly to to Charegebee
     * so we need to poll the credit cards to check if the credit card is saved
     * we are polling the credit cards every 1 second for 10 seconds
     */
    intervalRef.current = setInterval(async () => {
      const result = await fetchCreditCards()
      if (isRejected(result)) {
        setIsPolling(false)

        return
      }

      if ((result.data?.count ?? 0) > 0) {
        intervalRef.current && clearInterval(intervalRef.current)
        timeoutRef.current && clearInterval(timeoutRef.current)
        dispatch(api.util.invalidateTags(["Payments"]))
        setIsPolling(false)
        pushToNextOr(BILLING_PATHS.overview.root)
      }
    }, CREDIT_CARDS_POLLING_INTERVAL)

    timeoutRef.current = setTimeout(() => {
      intervalRef.current && clearInterval(intervalRef.current)
      dispatch(api.util.invalidateTags(["Payments"]))
      setIsPolling(false)
      pushToNextOr(BILLING_PATHS.overview.root)
    }, CREDIT_CARDS_POLLING_TIMEOUT)
  }

  /**
   *  UseEffect
   */
  useEffect(() => {
    reset(defaultValues)
  }, [defaultValues, reset])

  useEffect(
    () => () => {
      intervalRef.current && clearInterval(intervalRef.current)
      timeoutRef.current && clearInterval(timeoutRef.current)
    },
    [],
  )

  /**
   *  Render
   */
  return (
    <View className="PaymentMethod">
      <Breadcrumbs
        depth={2}
        includeParamsAsPath
        values={[
          t("desktop.settings.billing.title"),
          t("desktop.settings.billing.billing_details_form.title"),
        ]}
      />

      <Space size={0.75} />

      <Stepper steps={STEPS} currentStep="payment" onClick={() => {}} />

      <Space size={0.75} />

      {isLoading || isPolling ? (
        <>
          <Loader className="loader" />
          {isPolling && (
            <div className="poling-credit-card">
              <Trans
                i18nKey={
                  "desktop.settings.billing.payment_details.syncing_credit_card"
                }
              />
            </div>
          )}
        </>
      ) : (
        <FormProvider {...methods}>
          <PageForm
            className="PaymentMethod__form"
            updateMode={true}
            backUrl={BILLING_PATHS.overview.companyDetails}
            onUpdate={handleOnUpdate}
            submitButtonText={t("general.next")}
            disabled={isCreditCardTypeWithoutCreditCard || isPolling}
          >
            <div className="PaymentMethod__form__card">
              {!payments?.type && selectedType === PAYMENT_METHODS.NONE ? (
                <div className="PaymentMethod__form__no-payment">
                  {t(
                    "desktop.settings.billing.payment_method.no_payment_method",
                  )}
                </div>
              ) : null}
              <Field control={control} name={"type"}>
                {(props) => (
                  <RadioGroup
                    {...props}
                    options={translatedPaymentMethodOptions}
                    display="vertical"
                    tooltipClassName="radio-tooltip-content"
                  />
                )}
              </Field>

              {selectedType === PAYMENT_METHODS.PREPAID_CODE ? (
                <>
                  <PrepaidCodeForm
                    control={control}
                    watch={watch}
                    isSubmitting={isSubmitting}
                  />
                  <Button onClick={handleRedeemCode}>
                    {t(
                      "desktop.settings.billing.payment_method.buttons.redeem_code",
                    )}
                  </Button>
                </>
              ) : null}

              {selectedType === PAYMENT_METHODS.CREDIT_CARD ? (
                <CreditCard onSuccess={handleManageCreditCardSuccess} />
              ) : null}
            </div>

            {selectedType === PAYMENT_METHODS.PREPAID_CODE &&
            subscriptionsWithPrepaidCode.length > 0 ? (
              <Card className="PaymentMethod__prepaid-codes">
                <div className="PaymentMethod__prepaid-codes__title">
                  {t(
                    "desktop.settings.billing.payment_method.applied_coupon_codes",
                  )}
                </div>
                {subscriptionsWithPrepaidCode.map((s, index) => (
                  <PrepaidCodeInfo
                    key={s.subscription_id}
                    subscription={s}
                    index={index + 1}
                  />
                ))}
              </Card>
            ) : null}
          </PageForm>
        </FormProvider>
      )}
    </View>
  )
}

export default PaymentMethod
