import React, { useCallback, useMemo, useState } from "react"

import dayjs from "dayjs"
import { useTranslation } from "react-i18next"

import { FETCH_WITH_NO_LIMIT, LabelPaths } from "../../../../../constants"
import { useNavigation } from "../../../../../hooks/useNavigation"
import { useStoredFilter } from "../../../../../hooks/useStoredFilter"
import {
  KeyAndName,
  OptionalKeysRecord,
} from "../../../../../types/sharedTypes"
import { getLabel } from "../../../../../utils"
import DevicesFilters, { Filter, FilterKeys } from "../../Devices/Filters"
import { useCustomContentContext } from "../CustomContentContext"

import { useFetchRoomsDevicesQuery } from "../../../../../redux/api/devices"
import { DEVICE_TYPES } from "../../../../../redux/api/devices/constants"
import {
  DevicesFacets,
  RoomDeviceResponse,
} from "../../../../../redux/api/devices/types"

import Table from "../../../../../components/advanced/Table"
import { Column } from "../../../../../components/basic/Table"
import { Tooltip } from "../../../../../components/basic/Tooltip"
import { FilterSpecialValues } from "../../../../../components/Filter/types"
import PageForm from "../../../../../components/Form/PageFormHook"
import NoDataFound from "../../../../../components/NoDataFound"
import Space from "../../../../../components/Space"
import RoomDeviceStatus from "../../../../../components/Visitors/RoomDeviceStatus"

import { ReactComponent as InfoSVG } from "../../../../../assets/images/icons/InfoOutlined.svg"

import "./EditDevicesForm.sass"

const NoDevicesFound = () => {
  const { t } = useTranslation()

  return (
    <NoDataFound>
      <>
        <InfoSVG />
        <div>{t("desktop.settings.visitors.devices.no_devices.title")}</div>
      </>
    </NoDataFound>
  )
}

const DEVICE_FACETS = ["building", "floor"] as const

const queryUseFacets = DEVICE_FACETS.map(
  (facet) => `${facet}:all` as DevicesFacets,
)

const mapFacetToFilters: OptionalKeysRecord<DevicesFacets, FilterKeys> = {
  building: "building_id",
  floor: "floor_id",
}

const EditDevicesForm = () => {
  const { t } = useTranslation()

  const { push } = useNavigation()

  const { devices, onChangeDevices, paths } = useCustomContentContext()

  const defaultFilter: Filter = {
    building_id: FilterSpecialValues.ALL,
    floor_id: FilterSpecialValues.ALL,
    type: FilterSpecialValues.ALL,
    battery: FilterSpecialValues.ALL,
    connectivity: FilterSpecialValues.ALL,
    search: "",
  }

  const [storedFilterValues] = useStoredFilter({
    defaultFilterValues: defaultFilter,
  })

  const [filters, setFilters] = useState<Filter>(storedFilterValues)

  const handleFilterChange = useCallback(async (filter: Filter) => {
    setFilters(filter)
  }, [])

  const [selectedRows, setSelectedRows] = useState<RoomDeviceResponse[]>([])

  const selectedIdsDevices = useMemo(
    () => selectedRows.map((sd) => sd.id),
    [selectedRows],
  )

  const {
    data: { results: roomsDevices = [], facets = {} } = {},
    isFetching: isLoading,
  } = useFetchRoomsDevicesQuery({
    facets: queryUseFacets,
    building: filters.building_id,
    floor: filters.floor_id,
    type: filters.type,
    battery: filters.battery,
    connectivity: filters.connectivity,
    limit: FETCH_WITH_NO_LIMIT,
    search: filters.search,
  })

  // BE doesn't support filtering by multiple types
  const devicesWithCustomContent = useMemo(
    () =>
      roomsDevices.filter(
        (d) =>
          d.type === DEVICE_TYPES.joan_6 ||
          d.type === DEVICE_TYPES.joan_13 ||
          d.type === DEVICE_TYPES.joan_32,
      ),
    [roomsDevices],
  )

  const handleSelectRow = (d: RoomDeviceResponse[]) => setSelectedRows(d)

  const initialSelectedRows = useMemo(
    () => devicesWithCustomContent.filter((d) => devices.includes(d.id)),
    [devices, devicesWithCustomContent],
  )

  const tableColumns = useMemo<Column<RoomDeviceResponse>[]>(
    () => [
      {
        field: "name",
        label: t("desktop.settings.rooms.devices.table.device_name"),
        renderCell: ({ name, type }) => {
          const deviceType = getLabel(
            `deviceTypes.${type.toLowerCase()}` as LabelPaths,
          )

          return deviceType + (name && ` - ${name}`)
        },
      },
      {
        cellClassName: "rooms",
        field: "rooms",
        label: t("desktop.settings.rooms.devices.table.calendar"),
        renderCell: ({ rooms }) => roomsDevicesCell(rooms),
      },
      {
        field: "type",
        label: t("desktop.settings.rooms.devices.table.device_status"),
        renderCell: (device) => <RoomDeviceStatus device={device} />,
      },
      {
        field: "status_sent_at",
        cellClassName: "last-reported",
        label: t("desktop.settings.rooms.devices.table.last_reported"),
        renderCell: ({ status_sent_at }) =>
          status_sent_at ? dayjs(status_sent_at).fromNow() : "-",
      },
    ],
    [t],
  )

  const roomsDevicesCell = (rooms: KeyAndName[]) => {
    if (rooms.length) {
      return (
        <>
          {rooms.length === 1 ? (
            <Tooltip
              className="single-room"
              key={rooms[0].key}
              uniqueId={`${rooms[0].key}`}
              content={rooms[0].name}
            >
              {rooms[0].name}
            </Tooltip>
          ) : (
            rooms.map((room, index, array) => {
              const isLastElement = index === array.length - 1

              return (
                <span key={room?.name ?? index}>
                  {room?.name ?? ""}
                  {!isLastElement ? ", " : ""}
                </span>
              )
            })
          )}
        </>
      )
    } else return "-"
  }

  const onUpdateClick = useCallback(async () => {
    onChangeDevices(selectedIdsDevices)

    push(paths.root)
  }, [onChangeDevices, paths.root, push, selectedIdsDevices])

  return (
    <div className="EditDevicesForm">
      <PageForm updateMode onUpdate={onUpdateClick} backUrl={paths.root}>
        <div>
          <DevicesFilters
            onChange={handleFilterChange}
            defaultValues={storedFilterValues}
            facets={facets}
            mapFacetToFilters={mapFacetToFilters}
            useFacets={DEVICE_FACETS}
            showCount={false}
          />

          <Space size={0.75} />

          <Table
            isSelectable
            loading={isLoading}
            rows={devicesWithCustomContent}
            columns={tableColumns}
            initialSelectedRows={initialSelectedRows}
            onSelectedRows={handleSelectRow}
            emptyTableCell={<NoDevicesFound />}
          />
        </div>
      </PageForm>
    </div>
  )
}

export default EditDevicesForm
