import React, { useEffect, useState } from "react"

import CopyToClipBoardFunction from "react-copy-to-clipboard"

import { ReactComponent as CopySVG } from "../../../assets/images/icons/Copy.svg"

import "./style.sass"

type CopyToClipboardTextProps = {
  text: string
  successText?: string
}
const SUCCESS_ACTION_TIMER = 2000

const CopyToClipboard = ({
  text,
  successText = "Copied!",
}: CopyToClipboardTextProps) => {
  const [wasCopied, setWasCopied] = useState<boolean>(false)

  useEffect(() => {
    let timer: NodeJS.Timeout
    if (wasCopied) {
      timer = setTimeout(() => {
        setWasCopied(false)
      }, SUCCESS_ACTION_TIMER)
    }
    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [wasCopied])

  const handleOnClick = () => {
    setWasCopied(true)
  }

  return (
    <div className="copy-to-clipboard">
      <input
        type="text"
        name="copy-to-clipboard"
        className="copy-to-clipboard-text"
        value={text}
        disabled
      />
      <CopyToClipBoardFunction text={text}>
        {!wasCopied ? (
          <CopySVG onClick={handleOnClick} className="copy-to-clipboard-icon" />
        ) : (
          <p className="copy-to-clipboard-success">{successText}</p>
        )}
      </CopyToClipBoardFunction>
    </div>
  )
}

export default CopyToClipboard
