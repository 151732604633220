import React, { useState } from "react"

import { t } from "i18next"
import { Trans } from "react-i18next"

import { useNavigation } from "../../../../hooks/useNavigation"
import { getLabel } from "../../../../utils"
import BillingSection from "../BillingSection"
import { BILLING_PATHS, getPlanGroupPath } from "../constants"
import PlanVariationCard from "./PlanVariationCard"

import {
  CURRENCIES,
  SUBSCRIPTION_PERIODS,
} from "../../../../redux/api/billing/constants"
import { useFetchPlansQuery } from "../../../../redux/api/billing/plans"
import {
  Currency,
  PlanType,
  SubscriptionPeriod,
} from "../../../../redux/api/billing/types"
import {
  getPlanGroup,
  groupToTranslationKey,
} from "../../../../redux/api/billing/utils"

import Button from "../../../../components/advanced/Button"
import MultiToggle from "../../../../components/basic/MultiToggle"
import Breadcrumbs from "../../../../components/Breadcrumbs"
import Space from "../../../../components/Space"
import View from "../../../../components/View"

import "./PlanSelection.sass"

type QueryProps = {
  planType: PlanType
  currency: Currency
  period: SubscriptionPeriod
  id?: string
}

const PlanSelection = () => {
  const { data: plans, isLoading } = useFetchPlansQuery()
  const history = useNavigation()
  const {
    searchParams,
    location: { search },
    createLocationString,
  } = history

  const {
    id,
    planType,
    period = SUBSCRIPTION_PERIODS.MONTH,
    currency = CURRENCIES.EUR,
  } = (searchParams ?? {}) as QueryProps
  const [selectedPeriod, setSelectedPeriod] = useState(
    period as SubscriptionPeriod,
  )

  if (!plans) {
    return null
  }

  const planGroup = getPlanGroup(planType)

  const currentPlanGroupPlans = plans
    .filter((plan) => plan.planGroup === planGroup && plan.visible)
    .map((plan) => ({
      ...plan,
      plans: plan.plans.find(
        (p) => p.period_unit === selectedPeriod && p.currency === currency,
      ),
    }))
    .sort((a, b) => (a.plans?.plan_price ?? 0) - (b.plans?.plan_price ?? 0))

  const handleOnPlanSelect = (selectedPlanType: PlanType) => {
    history.pushWithNext(getPlanGroupPath(planGroup).root, {
      planType: selectedPlanType,
      currency,
      period: selectedPeriod,
      id: id,
    })
  }

  return (
    <View className="PlanSelection">
      <Breadcrumbs
        depth={3}
        values={[
          t("desktop.settings.billing.title"),
          t(groupToTranslationKey(planGroup)),
          t("desktop.settings.billing.plan_selection.title"),
        ]}
        urls={[
          BILLING_PATHS.root,
          createLocationString(getPlanGroupPath(planGroup).root, {
            ...searchParams,
          }),
        ]}
      />

      <Space size={0.75} />

      <MultiToggle
        options={[
          {
            label: t("desktop.settings.billing.plan_selection.billed", {
              period: t(
                "desktop.settings.billing.subscription_quantity.monthly",
              ),
            }),
            value: SUBSCRIPTION_PERIODS.MONTH,
          },
          {
            label: t("desktop.settings.billing.plan_selection.billed", {
              period: t(
                "desktop.settings.billing.subscription_quantity.yearly",
              ),
            }),
            value: SUBSCRIPTION_PERIODS.YEAR,
          },
        ]}
        value={selectedPeriod}
        onChange={(value) => setSelectedPeriod(value)}
      />

      <Space size={0.75} />

      <div className="PlanSelection__list">
        {currentPlanGroupPlans.map((groupPlans, index) => {
          if (!groupPlans.plans) {
            return null
          }

          return (
            <PlanVariationCard
              planType={groupPlans.plan_type}
              subscriptionPeriod={selectedPeriod as SubscriptionPeriod}
              isCurrentPlan={
                planType === groupPlans.plan_type &&
                period === groupPlans.plans.period_unit
              }
              isSelected={index === 1}
              plan={groupPlans.plans}
              key={groupPlans.plan_type}
              onSelectPlan={handleOnPlanSelect}
            />
          )
        })}
      </div>

      <Button
        variant="link"
        onClick={() => history.push(`/settings/billing/${planGroup}${search}`)}
      >
        {t("general.back")}
      </Button>

      <Space size={1.5} />

      <BillingSection
        title={t("desktop.settings.billing.plan_selection.custom")}
      >
        <div className="info">
          <Trans i18nKey="desktop.settings.billing.plan_selection.custom_plan_info" />
        </div>
        <Button
          variant="secondary-white"
          href={getLabel("links.salesFormUrl")}
          target="_blank"
        >
          {t("general.contact_sales")}
        </Button>
      </BillingSection>
    </View>
  )
}

export default PlanSelection
