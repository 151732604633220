import React, { useCallback, useEffect, useMemo } from "react"

import { useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { useNavigation } from "../../../../hooks/useNavigation"
import { useToast } from "../../../../hooks/useToast"
import { getOption } from "../../../../utils"
import {
  CUSTOM_CONTENT_PATHS,
  REPEATS_WEEKLY_SUFFIX,
  SCHEDULE_REPEATS_OPTIONS,
  SCHEDULE_REPEATS_TYPES,
} from "./constants"
import ContentGrid from "./ContentGrid"
import ContentTypeSelection from "./ContentTypeSelection"
import { FormValues, useCustomContentContext } from "./CustomContentContext"
import DevicesSection from "./SettingsSection/DevicesSection"
import ScheduleSection from "./SettingsSection/ScheduleSection"
import TitleSection from "./SettingsSection/TitleSection"
import { getSectionCount } from "./utils"

import {
  useCreateCustomContentMutation,
  useUpdateCustomContentMutation,
} from "../../../../redux/api/customContent"
import { CustomContentResponse } from "../../../../redux/api/customContent/types"
import { isRejected } from "../../../../redux/api/types"

import PageForm from "../../../../components/Form/PageFormHook"

import "./EditCustomContentForm.sass"

type Props = {
  customContent?: CustomContentResponse
}

const EditCustomContentForm = ({ customContent }: Props) => {
  const { t } = useTranslation()
  const { push } = useNavigation()

  const { errorToast, infoToast } = useToast()

  const {
    isUpdateMode,
    content: { type },
  } = useCustomContentContext()

  const {
    reset,
    formState: { isDirty },
  } = useFormContext()

  const [createCustomContent] = useCreateCustomContentMutation()
  const [updateCustomContent] = useUpdateCustomContentMutation()

  const numberOfSections = useMemo(() => getSectionCount(type), [type])

  const onCreateClick = useCallback(
    async (values: FormValues) => {
      const response = await createCustomContent({
        ...values,
        content: {
          ...values.content,
          sections: values.content.sections.slice(0, numberOfSections),
        },
        schedule: values.schedule
          ? {
              ...values.schedule,
              repeat:
                values.schedule.repeat?.value ===
                SCHEDULE_REPEATS_TYPES.NEVER_REPEATS
                  ? null
                  : (values.schedule.repeat?.value ?? null),
            }
          : null,
      })

      if (isRejected(response)) {
        errorToast(response.error.message)

        return
      }

      push(CUSTOM_CONTENT_PATHS.root)

      infoToast(
        t(
          "desktop.settings.rooms.custom_content.forms.general.toasts.custom_content_created",
        ),
      )
    },
    [createCustomContent, numberOfSections, push, infoToast, t, errorToast],
  )

  const onUpdateClick = useCallback(
    async (values: FormValues) => {
      const response = await updateCustomContent({
        ...values,
        content: {
          ...values.content,
          sections: values.content.sections.slice(0, numberOfSections),
        },
        schedule: values.schedule
          ? {
              ...values.schedule,
              repeat:
                values.schedule.repeat?.value ===
                SCHEDULE_REPEATS_TYPES.NEVER_REPEATS
                  ? null
                  : (values.schedule.repeat?.value ?? null),
            }
          : null,
      })

      if (isRejected(response)) {
        errorToast(response.error.message)

        return
      }

      push(CUSTOM_CONTENT_PATHS.root)

      infoToast(
        t(
          "desktop.settings.rooms.custom_content.forms.general.toasts.custom_content_updated",
        ),
      )
    },
    [updateCustomContent, numberOfSections, push, infoToast, t, errorToast],
  )

  const selectedRepeatOption = useMemo(() => {
    if (!customContent?.schedule) {
      return SCHEDULE_REPEATS_OPTIONS[0]
    }

    const { schedule } = customContent

    if (schedule.repeat === null) {
      return SCHEDULE_REPEATS_OPTIONS[0]
    }

    if (schedule.repeat.includes(REPEATS_WEEKLY_SUFFIX)) {
      return { ...SCHEDULE_REPEATS_OPTIONS[2], value: schedule.repeat }
    }

    return (
      getOption(SCHEDULE_REPEATS_OPTIONS, schedule.repeat) ||
      SCHEDULE_REPEATS_OPTIONS[0]
    )
  }, [customContent])

  useEffect(() => {
    if (!customContent || isDirty) {
      return
    }

    const updatedContent: FormValues = {
      ...customContent,
      schedule: customContent.schedule
        ? {
            ...customContent.schedule,
            repeat: selectedRepeatOption,
          }
        : null,
    }

    reset(updatedContent)
  }, [customContent, isDirty, reset, selectedRepeatOption])

  return (
    <div className="EditCustomContentForm">
      <PageForm
        updateMode={isUpdateMode}
        onCreate={onCreateClick}
        onUpdate={onUpdateClick}
        backUrl={CUSTOM_CONTENT_PATHS.root}
        header={
          <div className="custom-content-header">
            <div className="custom-content-header__controls">
              <ContentTypeSelection />
            </div>
          </div>
        }
      >
        <div>
          <ContentGrid />

          <div className="SettingsSections">
            <TitleSection />

            <DevicesSection />

            <ScheduleSection />
          </div>
        </div>
      </PageForm>
    </div>
  )
}

export default EditCustomContentForm
