import React, { useEffect, useState } from "react"

import classNames from "classnames"
import dayjs from "dayjs"
import { Trans, useTranslation } from "react-i18next"

import { API_ROOT } from "../../../api/urls"
import {
  ENTRIES_PER_PAGE_KEY,
  FEATURE_FLAGS,
  PERMISSIONS,
} from "../../../constants"
import { useBackendPagination } from "../../../hooks/useBackendPagination"
import { useCheckForFeatureFlag } from "../../../hooks/useCheckForFeatureFlag"
import { useCheckForPermission } from "../../../hooks/useCheckForPermission"
import { useLocalStorage } from "../../../hooks/useLocalStorage"
import { useNavigation } from "../../../hooks/useNavigation"
import CSVImportModal from "../../../modals/CSVImportModal"
import { getLabel } from "../../../utils"
import { INTEGRATIONS_PATHS } from "../Integrations/constants"
import LicensesInfoList from "../LicensesInfoList"
import { USER_DIRECTORY_PATHS } from "./constants"
import { useModals } from "@mattjennings/react-modal-stack"

import { useFetchMeQuery } from "../../../redux/api/me"
import { MeResponse } from "../../../redux/api/me/types"
import { useFetchScimSettingsQuery } from "../../../redux/api/scim"
import { ENTRIES_PER_PAGE, useFetchUsersQuery } from "../../../redux/api/users"
import { isLicenseFilter, UserResponse } from "../../../redux/api/users/types"
import { UserGroup } from "../../../redux/user/types"
import { formatUser } from "../../../redux/user/utils"

import Button from "../../../components/advanced/Button"
import Card from "../../../components/basic/Card"
import Divider from "../../../components/basic/Divider"
import Loader from "../../../components/basic/Loader"
import DepartmentFilter from "../../../components/Filter/DepartmentFilter"
import Filters from "../../../components/Filter/Filters"
import FilterSpace from "../../../components/Filter/FilterSpace"
import LicenseFilter from "../../../components/Filter/LicenseFilter"
import RoleFilter from "../../../components/Filter/RoleFilter"
import SearchFilter from "../../../components/Filter/SearchFilter"
import { FilterSpecialValues } from "../../../components/Filter/types"
import Heading from "../../../components/Heading"
import Intro from "../../../components/Intro"
import LicenseIndicator from "../../../components/LicenseIndicator"
import Pagination from "../../../components/Pagination"
import Space from "../../../components/Space"
import View from "../../../components/View"

import { ReactComponent as UserDirectorySvg } from "../../../assets/images/icons/UserDirectory.svg"

import "./UserDirectory.sass"

const scimURL = `${API_ROOT}/settings/integrations/scim/?kiosk=1`

const getUserRole = (person: UserResponse) => {
  if (!person.groups) return "/ User"

  if (person.groups.includes("portal_officer_manager"))
    return "/ Office Manager"

  if (person.groups.includes("portal_admin")) return "/ IT Admin"

  return "/ User"
}

const formatLocation = (
  jobRole: string | null | undefined,
  jobTitle: string | null | undefined,
  buildingName: string | null | undefined,
) => {
  return [jobRole, jobTitle, buildingName].filter((f) => f).join(" / ")
}

const IS_LAST_LOGGED_IN_ATTRIBUTE_CORRECT =
  process.env.REACT_APP_IS_LAST_LOGGED_IN_ATTRIBUTE_CORRECT

const UserDirectory = () => {
  const { push } = useNavigation()
  const { openModal } = useModals()
  const { t } = useTranslation()

  // Filters
  const [page, setCurrentPage] = useState(1)
  const [search, setSearch] = useState<string>(FilterSpecialValues.EMPTY)
  const [roleFilter, setRoleFilter] = useState<string>(FilterSpecialValues.ALL)
  const [licenseFilter, setLicenseFilter] = useState<string>(
    FilterSpecialValues.ALL,
  )
  const [departmentFilter, setDepartmentFilter] = useState<string>(
    FilterSpecialValues.ALL,
  )

  const isAllFilter = (value: string) => value === FilterSpecialValues.ALL

  const { data: user, isLoading: isUserLoading } = useFetchMeQuery()

  const canAddUsersCSVs = useCheckForPermission(PERMISSIONS.users.canChangeUser)

  const { value: entriesPerPage, onChange: setEntriesPerPage } =
    useLocalStorage(ENTRIES_PER_PAGE_KEY, ENTRIES_PER_PAGE.toString())
  const entriesPerPageNum = parseInt(entriesPerPage)

  const offset = (page - 1) * entriesPerPageNum

  const {
    data: { count = 0, results: people = [] } = {},
    isLoading: arePeopleLoading,
  } = useFetchUsersQuery({
    offset,
    limit: entriesPerPageNum,
    search: search,
    ...(!isAllFilter(roleFilter) && { role_id: roleFilter }),
    ...(!isAllFilter(departmentFilter) && {
      department_id: departmentFilter,
    }),
    ...(isLicenseFilter(licenseFilter) && { license: licenseFilter }),
  })

  const { from, to, hasNext, hasPrevious, paginationLinks } =
    useBackendPagination({
      offset,
      totalNumberOfItems: count,
      entriesPerPage: entriesPerPageNum,
      maxLinks: 7,
      maxTrailingLinks: 2,
    })

  const handlePrevClick = () => {
    setCurrentPage(page - 1)
  }

  const handleNextClick = () => {
    setCurrentPage(page + 1)
  }

  const handlePageClick = (page: number) => {
    setCurrentPage(page)
  }

  const handleEditClick = (person: UserResponse) => {
    push(USER_DIRECTORY_PATHS.edit.replace(":user_email", person.email))
  }

  const handleNewClick = () => {
    push(USER_DIRECTORY_PATHS.add)
  }

  useEffect(() => {
    setCurrentPage(1)
  }, [search, roleFilter, departmentFilter, licenseFilter])

  const userCount = people.filter((u: UserResponse) => !!u.last_login).length
  const employeeNoun = t("desktop.settings.people.person", { count: userCount })
  const employeeVerb = t("desktop.settings.people.verb", { count: userCount })

  const { data: scimSettings } = useFetchScimSettingsQuery()

  const { enabled: isSCIMEnabled } = scimSettings ?? {}
  const isEditingEnabled = !isSCIMEnabled

  const isPortalAdmin = user?.groups?.includes(UserGroup.ADMIN) ?? false

  const cardClassNames = classNames({
    people: true,
    isLoading: arePeopleLoading,
  })

  const isPortalMergerEnabled = useCheckForFeatureFlag(
    FEATURE_FLAGS.PORTAL_MERGER,
  )

  return (
    <View className="UserDirectory">
      <Heading>{t("desktop.settings.people.title")}</Heading>

      <Intro>
        <div className="description">
          {isSCIMEnabled ? (
            <p>{t("desktop.settings.people.scim_enabled")}</p>
          ) : (
            <p>
              {t("desktop.settings.people.intro")}{" "}
              {isPortalAdmin ? (
                <>
                  <Trans i18nKey={"desktop.settings.people.intro_admin"}>
                    {isPortalMergerEnabled ? (
                      <Button
                        className="scim-link"
                        variant="link"
                        onClick={() => {
                          push(INTEGRATIONS_PATHS.scim)
                        }}
                      >
                        scim
                      </Button>
                    ) : (
                      <a href={scimURL}>scim</a>
                    )}
                  </Trans>
                </>
              ) : (
                <>{t("desktop.settings.people.intro_user")}</>
              )}
            </p>
          )}

          {IS_LAST_LOGGED_IN_ATTRIBUTE_CORRECT && !arePeopleLoading && (
            <p>
              <strong>
                {userCount} {employeeNoun}
              </strong>{" "}
              {t("desktop.settings.people.actively_signed", {
                verb: employeeVerb,
              })}{" "}
            </p>
          )}

          {isSCIMEnabled && (
            <p className="notice">
              {t("desktop.settings.people.scim_notice")}
              <br />
              <br />
              {isPortalAdmin ? (
                <>
                  <Trans
                    i18nKey={
                      "desktop.settings.people.scim_notice_setting_admin"
                    }
                  >
                    {isPortalMergerEnabled ? (
                      <Button
                        className="scim-link"
                        variant="link"
                        onClick={() => {
                          push(INTEGRATIONS_PATHS.scim)
                        }}
                      >
                        scim
                      </Button>
                    ) : (
                      <a href={scimURL}>scim</a>
                    )}
                  </Trans>
                </>
              ) : (
                <>{t("desktop.settings.people.scim_notice_setting_user")}</>
              )}
            </p>
          )}
        </div>
        {(canAddUsersCSVs || isEditingEnabled) && (
          <div className="buttons">
            {isEditingEnabled && (
              <Button
                onClick={() => handleNewClick()}
                isDisabled={arePeopleLoading}
                isSmall
              >
                {t("desktop.settings.people.add_user")}
              </Button>
            )}
            {canAddUsersCSVs && (
              <Button
                onClick={() => openModal(CSVImportModal)}
                variant="secondary-white"
                className="import-csv"
                isDisabled={arePeopleLoading}
                isSmall
              >
                {t("desktop.settings.people.import_csv")}
              </Button>
            )}
          </div>
        )}
      </Intro>

      <Space size={0.75} />

      <LicensesInfoList
        licenseType="user"
        tooltipContent={
          <div className="people-license-tooltip">
            {t("desktop.settings.people.license_tooltip")}
          </div>
        }
      />

      <Space size={0.75} />

      <Filters>
        <RoleFilter value={roleFilter} onChange={setRoleFilter} />
        <DepartmentFilter
          value={departmentFilter}
          onChange={setDepartmentFilter}
          showAll={true}
        />

        <LicenseFilter value={licenseFilter} onChange={setLicenseFilter} />
        <FilterSpace />
        <SearchFilter
          value={search}
          onChange={setSearch}
          placeholder={t("desktop.settings.people.search_placeholder")}
        />
      </Filters>

      <Space size={0.75} />

      {(isUserLoading || arePeopleLoading) && <Loader className="loader" />}

      {!isUserLoading && !arePeopleLoading && (
        <Card className={cardClassNames}>
          {people.length === 0 && !arePeopleLoading && (
            <div className="no-people">
              <UserDirectorySvg />
              <p className="message">{t("desktop.settings.people.no_users")}</p>
            </div>
          )}

          {user &&
            people.length > 0 &&
            people.map((person: UserResponse, i: number) => (
              <PeopleItem
                key={`person-${i}`}
                person={person}
                user={user}
                onEditClick={handleEditClick}
                arePeopleLoading={arePeopleLoading}
                amPortalAdmin={isPortalAdmin}
              />
            ))}

          {people.length > 0 && (
            <>
              <Divider hasMargin={false} />
              <Pagination
                links={paginationLinks}
                setPage={handlePageClick}
                onPrevious={handlePrevClick}
                onNext={handleNextClick}
                hasNext={hasNext}
                hasPrevious={hasPrevious}
                from={from}
                to={to}
                total={count}
                items={t("desktop.settings.people.person", {
                  count: people.length,
                })}
                entriesPerPage={entriesPerPageNum}
                setEntriesPerPage={setEntriesPerPage}
              />
            </>
          )}
        </Card>
      )}

      {people.length === 0 && !arePeopleLoading && (
        <Card className="onboarding">
          <div>
            <Trans i18nKey={"desktop.settings.people.onboarding"}>
              <Button
                onClick={() => {
                  handleNewClick()
                }}
                variant="link"
              ></Button>
              <Button
                onClick={() => {
                  openModal(CSVImportModal)
                }}
                variant="link"
              ></Button>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={getLabel("links.howToInviteDeskBookingAppUsers")}
              >
                link
              </a>
            </Trans>
          </div>
        </Card>
      )}
    </View>
  )
}

export default UserDirectory

type PeopleItemProps = {
  person: UserResponse
  user: MeResponse
  arePeopleLoading: boolean
  onEditClick: (person: UserResponse) => void
  amPortalAdmin: boolean
}

const PeopleItem = ({
  person,
  onEditClick,
  user,
  arePeopleLoading,
  amPortalAdmin,
}: PeopleItemProps) => {
  const { t } = useTranslation()
  const isPersonAdmin = Boolean(person.groups?.includes("portal_admin"))

  const isEditShown = !(isPersonAdmin && !amPortalAdmin)

  return (
    <div className="person">
      <div className="data">
        <div className="primary">
          <span className="name">{formatUser(person)}</span>
          <div className="role">
            {person.email === user.email
              ? `/ ${t("desktop.settings.people.you")}`
              : getUserRole(person)}{" "}
            / {person.email}
          </div>
        </div>
        <div className="secondary">
          <div className="email">
            {formatLocation(
              person.profile?.job_role,
              person.profile?.job_title,
              person.building?.name,
            )}
          </div>
          {person.last_login && (
            <div className="last-login">
              {t("desktop.settings.people.last_signin")}{" "}
              {dayjs(person.last_login).format("ddd, D MMM, YYYY")}
            </div>
          )}
          <LicenseIndicator license={person.license} />
        </div>
      </div>
      {isEditShown && (
        <Button
          onClick={() => onEditClick(person)}
          isDisabled={arePeopleLoading}
          variant="secondary"
        >
          {t("general.edit")}
        </Button>
      )}
    </div>
  )
}
