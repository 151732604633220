import React from "react"

import { Trans } from "react-i18next"

import { getLabel } from "../utils"

import Heading from "../components/Heading"
import NoNav from "../components/NoNav"
import RouteView from "../components/RouteView"
import Screen from "../components/Screen"
import View from "../components/View"

import "./NotFound.sass"

function Notice() {
  const currentURL = window.location.href
  const supportEmail = getLabel("links.supportEmail")

  return (
    <View className="NotFoundNotice">
      <Heading>
        <Trans i18nKey="general.not_found_page.title">
          <strong>Error 404</strong> &nbsp;/&nbsp; <span>Page not found</span>
        </Trans>
      </Heading>
      <p>
        <Trans i18nKey="general.not_found_page.message" values={{ currentURL }}>
          We couldn't decide which page to render for the URL:
          <br />
          <code>{currentURL}</code>
        </Trans>
      </p>
      <p>
        <Trans
          i18nKey="general.not_found_page.contact_support"
          values={{ supportEmail }}
        >
          Please <a href={`mailto:${supportEmail}`}>contact support</a> if this
          is an application error.
        </Trans>
      </p>
    </View>
  )
}

export default function NotFound({ onlyView = false }) {
  if (onlyView) {
    return <Notice />
  }

  return (
    <RouteView className="NotFound">
      <Screen sidebar={<NoNav />}>
        <Notice />
      </Screen>
    </RouteView>
  )
}
