import React from "react"

import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"

import EditRoomForm from "./EditRoomForm"
import { skipToken } from "@reduxjs/toolkit/query"

import { useFetchRoomQuery } from "../../../../redux/api/rooms"

import Loader from "../../../../components/basic/Loader"
import Breadcrumbs from "../../../../components/Breadcrumbs"
import Intro from "../../../../components/Intro"
import NoDataFound from "../../../../components/NoDataFound"
import Space from "../../../../components/Space"
import View from "../../../../components/View"

import "./EditRoom.sass"

type ParamsType = {
  key: string
}

const EditRoom = () => {
  const { t } = useTranslation()

  const { key } = useParams<ParamsType>()

  const { data: room, isFetching: isLoading } = useFetchRoomQuery(
    decodeURIComponent(key) ?? skipToken,
  )

  const roomsNotFound = !!key && !room?.key

  return (
    <View className="EditSettingsRooms">
      <Breadcrumbs
        depth={3}
        values={[
          t("desktop.settings.rooms.title"),
          t("desktop.settings.rooms.rooms.title"),
          room?.key
            ? t("desktop.settings.rooms.rooms.form.edit_room_title")
            : t("desktop.settings.rooms.rooms.form.add_room_title"),
        ]}
      />
      <Intro isConstrained>{t("desktop.settings.rooms.rooms.intro")}</Intro>
      <Space size={0.75} />

      <div className="rooms-container">
        {isLoading ? (
          <Loader className="loader" />
        ) : roomsNotFound ? (
          <NoDataFound />
        ) : (
          <EditRoomForm room={room} />
        )}
      </div>
    </View>
  )
}

export default EditRoom
