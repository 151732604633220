import React from "react"

import { useTranslation } from "react-i18next"

import { TopNav } from "./TopNav"

import { DeleteType } from "../../redux/reservations/types"

import { ReactComponent as DeleteSVG } from "../../assets/images/icons/Delete.svg"

import "./ReservationDeleteMenu.sass"

type ReservationOptionMenuProps = {
  onClick: (type: DeleteType) => void
  onClose: () => void
}

export const ReservationDeleteMenu: React.FC<ReservationOptionMenuProps> = ({
  onClick,
  onClose,
}) => {
  const { t } = useTranslation()

  return (
    <div className="ReservationDeleteMenu">
      <div className="picker-content">
        <div className="section-head">
          <TopNav
            backArrow={false}
            title={t("mobile.home.delete_recurring")}
            titleCenter={true}
            onClose={onClose}
            onTopScreen={false}
          />
        </div>
        <div
          className="section"
          onClick={() => {
            onClick(DeleteType.CURRENT)
          }}
        >
          <div className="option">
            <div className="option-icon">
              <DeleteSVG />
            </div>
            <div className="option-name">
              {t("general.reservation.this_reservation")}
            </div>
          </div>
        </div>
        <div
          className="section"
          onClick={() => {
            onClick(DeleteType.CURRENT_FUTURE)
          }}
        >
          <div className="option">
            <div className="option-icon">
              <DeleteSVG />
            </div>
            <div className="option-name">
              {t("general.reservation.this_and_following_reservations")}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
