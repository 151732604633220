import { PropsWithChildren } from "react"

import classNames from "classnames"
import { useTranslation } from "react-i18next"

import { colorFromString, generateString } from "../../utils"
import Avatar from "../advanced/Avatar"
import { CorrectIconMapper } from "../CorrectIconMapper"
import UserTooltip from "../UserTooltip"

import { UserData } from "../../redux/user/types"

import { ReactComponent as EllipseSVG } from "../../assets/images/icons/Ellipse.inner.svg"
import { ReactComponent as SquareSVG } from "../../assets/images/icons/Square.inner.svg"

import "./ReservationData.sass"

const ICON_TYPES = {
  ellipse: EllipseSVG,
  square: SquareSVG,
}

type Props = {
  label: string
  onClick: () => void
  iconType?: "ellipse" | "square"
  showIcon?: boolean
  showOwnership?: boolean
  isYou?: boolean
  isPartial?: boolean
  idString?: string
  isVisit?: boolean
  user?: UserData
  disabled?: boolean
}

const ReservationData = ({
  children,
  label,
  onClick,
  iconType = "ellipse",
  showIcon = true,
  showOwnership = true,
  isYou = false,
  isPartial = false,
  idString = "",
  isVisit = false,
  user,
  disabled = false,
}: PropsWithChildren<Props>) => {
  const { t } = useTranslation()

  const iconStyle: any = {
    "--user-color": colorFromString(idString),
  }

  const IconSVG = ICON_TYPES[iconType]

  const reservationClassName = classNames("Reservation", "ReservationData", {
    isPartial: isPartial,
    disabled: disabled,
  })

  return (
    <div
      className={reservationClassName}
      onClick={() => !disabled && onClick()}
    >
      <span className="label">
        {showIcon &&
          (isVisit ? (
            <CorrectIconMapper
              iconType="visitor"
              needsWrap={false}
              className="badge"
            />
          ) : user ? (
            <Avatar size="medium" user={user} />
          ) : (
            <IconSVG style={iconStyle} />
          ))}
        <span className="name">
          {user ? (
            <UserTooltip user={user} uniqueId={generateString(4)}>
              {label}
            </UserTooltip>
          ) : (
            label
          )}

          {showOwnership && isYou && (
            <>
              {" "}
              <span className="you">{t("desktop.settings.people.you")}</span>
            </>
          )}
        </span>
      </span>
      {children}
    </div>
  )
}

export default ReservationData
