import React, { useCallback, useEffect, useMemo, useState } from "react"

import classNames from "classnames"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import PullToRefresh from "react-simple-pull-to-refresh"

import { useRefresh } from "../../../hooks/mobile/useRefresh"
import { useRefreshUser } from "../../../hooks/mobile/useRefreshUser"
import useCheckReservationWindowLength from "../../../hooks/useCheckReservationWindowLength"
import { useLoadReducers } from "../../../hooks/useLoadReducers"
import { useAnalyticsScreenView } from "../../../providers/Mobile/FirebaseAnalyticsProvider"

import { useFetchBuildingsQuery } from "../../../redux/api/buildings"
import { useFetchMyDeskReservationsQuery } from "../../../redux/api/deskReservations"
import { DeskReservation } from "../../../redux/api/deskReservations/types"
import { checkIn, dismissCheckin } from "../../../redux/app/appSlice"
import { AssetReservation } from "../../../redux/asset_reservation/types"
import {
  checkinEvent,
  fetchEventsRooms,
} from "../../../redux/events/eventsSlice"
import { selectEvents } from "../../../redux/events/selectors"
import { EventResponse } from "../../../redux/events/types"
import { normalizeEventEmail } from "../../../redux/events/utils"
import { selectRoomsSettings } from "../../../redux/experiments/selectors"
import { fetchInvites } from "../../../redux/invites/invitesSlice"
import { selectInvites } from "../../../redux/invites/selectors"
import { InviteListResponse } from "../../../redux/invites/types"
import { fetchMyAssetReservations } from "../../../redux/mobile/reservations/reservationsSlice"
import { selectMobileReservations } from "../../../redux/mobile/reservations/selectors"
import { RootState, useAppSelector } from "../../../redux/reducers"
import { selectUser } from "../../../redux/user/selectors"
import { fetchUser } from "../../../redux/user/userSlice"
import { useActions } from "../../../redux/utils"
import { selectVisitors } from "../../../redux/visitors/selectors"
import { VisitorListResponse } from "../../../redux/visitors/types"
import { fetchVisitors } from "../../../redux/visitors/visitorsSlice"

import Loader from "../../../components/basic/Loader"
import { AssetReservationRow } from "../../../components/Mobile/AssetReservationRow"
import { BottomNav } from "../../../components/Mobile/BottomNav"
import { CategoryRow } from "../../../components/Mobile/CategoryRow"
import { EventRow } from "../../../components/Mobile/EventRow"
import { InviteRow } from "../../../components/Mobile/InviteRow"
import { LaunchBar } from "../../../components/Mobile/LaunchBar"
import { ReservationRow } from "../../../components/Mobile/ReservationRow"
import SafeViewArea from "../../../components/Mobile/SafeViewArea"
import { VisitorRow } from "../../../components/Mobile/VisitorRow"

import { ReactComponent as RefreshSVG } from "../../../assets/images/icons/Refresh.svg"
import { ReactComponent as SearchOffSVG } from "../../../assets/images/icons/SearchOff.svg"

import "./Reservations.sass"

const RESERVATION_FETCH_STEP = 7

type DayRowProps = {
  dayReservations: DayReservations
  loadDays: number
}

const DayRow: React.FC<DayRowProps> = ({ dayReservations, loadDays }) => {
  const { t } = useTranslation()

  const today = dayjs()
  const isToday = today.isSame(dayReservations.date, "day")
  const isTomorrow = today.add(1, "day").isSame(dayReservations.date, "day")
  const formattedDay = dayjs(dayReservations.date).format("dddd DD MMM")

  const day = isToday
    ? t("mobile.general.today")
    : isTomorrow
      ? t("mobile.general.tomorrow")
      : formattedDay

  return (
    <CategoryRow name={day}>
      {dayReservations.reservations.map((reservation, index) => {
        return <ReservationRow key={index} reservation={reservation} />
      })}
      {dayReservations.events.map((event, index) => {
        return <EventRow key={index} event={event} />
      })}
      {dayReservations.assetReservations.map((assetReservation) => (
        <AssetReservationRow
          key={assetReservation.id}
          reservation={assetReservation}
          loadDays={loadDays}
        />
      ))}
      {dayReservations.visitors.map((visitor) => (
        <VisitorRow key={visitor.id} visitor={visitor} />
      ))}
      {dayReservations.invites.map((invite) => (
        <InviteRow key={invite.id} invite={invite} />
      ))}
    </CategoryRow>
  )
}

type DayReservations = {
  date: string
  reservations: DeskReservation[]
  events: EventResponse[]
  assetReservations: AssetReservation[]
  visitors: VisitorListResponse[]
  invites: InviteListResponse[]
}

const Reservations: React.FC = () => {
  useAnalyticsScreenView("Home/Reservations")
  const { t } = useTranslation()

  const [loadDays, setLoadDays] = useState(RESERVATION_FETCH_STEP)
  const date = dayjs().startOf("day")
  const startDate = date.toISOString()
  const endDate = date.add(loadDays, "day").endOf("day").toISOString()

  const {
    data: { results: deskReservations = [] } = {},
    refetch,
    isSuccess,
    isLoading,
  } = useFetchMyDeskReservationsQuery({ start: startDate, end: endDate })
  const {
    data: { results: buildings = [] } = {},
    isSuccess: isDeskCountLoaded,
  } = useFetchBuildingsQuery({ stats: true })

  const actions = useActions({
    checkIn: (location_id: string) => checkIn(location_id),
    dismissCheckin: () => dismissCheckin(),
    fetchMyAssetsReservations: (start: string, end: string) =>
      fetchMyAssetReservations({ start, end }),
    checkinEvent: (event: EventResponse, lifetime: number) =>
      checkinEvent({ event, lifetime }),
    fetchEventsRooms: () => fetchEventsRooms(),
    fetchUser: () => fetchUser(),
    fetchVisitors: (start: string, end: string) =>
      fetchVisitors({ start, end, show: "my" }),
    fetchInvites: (start: string, end: string) =>
      fetchInvites({ start, end, show: "my" }),
  })

  useRefreshUser()

  useRefresh(() => {
    fetchData()
  })

  const { entry: user } = useAppSelector(selectUser)

  const desk_reservation_window_length = useCheckReservationWindowLength()

  const { entries: events } = useAppSelector(selectEvents)

  const { assetReservations } = useAppSelector(selectMobileReservations)

  const { entries: visitors } = useAppSelector(selectVisitors)

  const { entries: invites } = useAppSelector(selectInvites)

  const deskCount = useMemo(
    () =>
      buildings.reduce(
        (count, building) => count + (building.desks_count ?? 0),
        0,
      ) ?? 0,
    [buildings],
  )

  const fetchData = useCallback(() => {
    /**
     * Fetching events is currently really slow, no point in waiting.
     * Same as the useLoadReducers hook bellow.
     * */
    actions.fetchEventsRooms()
    refetch()

    return Promise.all([
      // actions.fetchAssets(), // we need to fetch assets so we know if we display the book asset button
      actions.fetchMyAssetsReservations(startDate, endDate),
      actions.fetchVisitors(startDate, endDate),
      actions.fetchInvites(startDate, endDate),
    ])
  }, [actions, endDate, refetch, startDate])

  const { areSlicesLoaded, areSlicesLoading } = useLoadReducers({
    reducerKeys: [
      "visitors",
      "invites",
      "mobile.reservations",
      /**
       * Fetching events is currently really slow, no point in waiting.
       * Same as the fetchData function above.
       * */
      // "rooms",
    ],
  })

  useEffect(() => {
    fetchData()
  }, [actions, loadDays, startDate, endDate, fetchData])

  const userDeskReservations: DeskReservation[] = deskReservations.filter(
    (reservation: DeskReservation) => {
      return reservation.visit_id === null
    },
  )

  const filteredEvents: EventResponse[] = events.filter(
    (event: EventResponse) => {
      // We filter by date so we don't show user events out of currently loaded reservations
      return (
        ((event.organizer &&
          normalizeEventEmail(event.organizer.email) ===
            normalizeEventEmail(user.email)) ||
          event.attendees.find(
            (a) =>
              normalizeEventEmail(a.mail) === normalizeEventEmail(user.email),
          ) !== undefined) &&
        dayjs(event.start).format("YYYY-MM-DD") <=
          dayjs().add(loadDays, "day").format("YYYY-MM-DD")
      )
    },
  )

  filteredEvents.sort((a, b) => {
    if (a.start < b.start) {
      return -1
    }
    if (a.start > b.start) {
      return 1
    }
    return 0
  })

  const { roomsSettings, isLoaded: areRoomSettingsLoaded } =
    useAppSelector(selectRoomsSettings)

  const roomBookingEnabled =
    !roomsSettings ||
    (roomsSettings &&
      roomsSettings.data &&
      roomsSettings.data.values &&
      roomsSettings.data.values.enabled)

  const deskBookingEnabled = isDeskCountLoaded && deskCount > 0

  const areReservationOptionsLoaded = isDeskCountLoaded && areRoomSettingsLoaded

  const { entries: rooms, isLoaded: areRoomsLoaded } = useAppSelector(
    (state: RootState) => state.rooms,
  )

  const daysReservations: Array<DayReservations> = []
  if (userDeskReservations.length > 0) {
    for (let reservation of userDeskReservations) {
      const eDate = dayjs(reservation.start).format("YYYY-MM-DD")

      const dayReservations = daysReservations.find((dr) => dr.date === eDate)
      if (dayReservations) {
        dayReservations.reservations.push(reservation)
      } else {
        daysReservations.push({
          date: eDate,
          reservations: [reservation],
          assetReservations: [],
          events: [],
          visitors: [],
          invites: [],
        })
      }
    }
  }

  const showRoomReservations = filteredEvents.length > 0 && roomBookingEnabled

  if (showRoomReservations) {
    for (let event of filteredEvents) {
      const eDate = dayjs(event.start).format("YYYY-MM-DD")
      const dayReservations = daysReservations.find((dr) => dr.date === eDate)

      if (dayReservations) {
        dayReservations.events.push(event)
      } else {
        daysReservations.push({
          date: eDate,
          reservations: [],
          assetReservations: [],
          events: [event],
          visitors: [],
          invites: [],
        })
      }
    }
  }

  const showAssetReservations = assetReservations.length > 0
  if (showAssetReservations) {
    assetReservations.forEach((assetReservation) => {
      const eDate = dayjs(assetReservation.start).format("YYYY-MM-DD")
      const dayReservations = daysReservations.find((dr) => dr.date === eDate)
      if (dayReservations) {
        dayReservations.assetReservations.push(assetReservation)
        return
      }

      daysReservations.push({
        date: eDate,
        reservations: [],
        assetReservations: [assetReservation],
        events: [],
        visitors: [],
        invites: [],
      })
    })
  }

  const showVisitors = visitors.length > 0

  if (showVisitors) {
    for (let visitor of visitors) {
      if (visitor.host?.id === user.email) {
        const vDate = dayjs(visitor.checkin_at).format("YYYY-MM-DD")
        const dayReservations = daysReservations.find((dr) => dr.date === vDate)

        if (dayReservations) {
          dayReservations.visitors.push(visitor)
        } else {
          daysReservations.push({
            date: vDate,
            reservations: [],
            assetReservations: [],
            events: [],
            visitors: [visitor],
            invites: [],
          })
        }
      }
    }
  }

  const showInvites = invites.length > 0

  if (showInvites) {
    for (let invite of invites) {
      if (invite.host?.id === user.email) {
        const iDate = dayjs(invite.start).format("YYYY-MM-DD")
        const dayReservations = daysReservations.find((dr) => dr.date === iDate)

        if (dayReservations) {
          dayReservations.invites.push(invite)
        } else {
          daysReservations.push({
            date: iDate,
            reservations: [],
            assetReservations: [],
            events: [],
            visitors: [],
            invites: [invite],
          })
        }
      }
    }
  }

  daysReservations.sort((a, b) => {
    if (a.date < b.date) {
      return -1
    }
    if (a.date > b.date) {
      return 1
    }
    return 0
  })

  const canMakeReservation = roomBookingEnabled || deskBookingEnabled

  const handleLoadMore = () => {
    setLoadDays(loadDays + RESERVATION_FETCH_STEP)
  }

  const isEmptyAccount =
    areReservationOptionsLoaded &&
    areRoomsLoaded &&
    (!canMakeReservation || (rooms.length === 0 && deskCount === 0))

  const reservationsClassName = classNames({
    Reservations: true,
    isLoading: !!areSlicesLoading && isLoading,
  })

  const showLoadMoreButton = () => {
    if (loadDays > (desk_reservation_window_length ?? 0)) return null

    return (
      <div className="load-more" onClick={handleLoadMore}>
        {areSlicesLoading ? <Loader /> : t("mobile.home.load_more")}
      </div>
    )
  }

  return (
    <SafeViewArea className={reservationsClassName}>
      <LaunchBar />
      <div className="body">
        <div className="list">
          {areSlicesLoaded && isSuccess ? (
            <div>
              <PullToRefresh
                onRefresh={fetchData}
                refreshingContent={<RefreshSVG className="refresh" />}
                pullingContent=""
              >
                <>
                  {daysReservations.length > 0 && (
                    <div className="reservations">
                      {daysReservations.map((dayReservations, index) => {
                        return (
                          <DayRow
                            dayReservations={dayReservations}
                            key={index}
                            loadDays={loadDays}
                          />
                        )
                      })}
                      {showLoadMoreButton()}
                    </div>
                  )}

                  {daysReservations.length === 0 &&
                    canMakeReservation &&
                    !isEmptyAccount && (
                      <div className="no-reservations">
                        <div className="info-icon">
                          <SearchOffSVG />
                        </div>
                        <div className="info-text">
                          {t("mobile.home.no_reservations_until", {
                            date: dayjs(endDate).format("dddd DD MMM"),
                          })}
                        </div>
                        {showLoadMoreButton()}
                      </div>
                    )}

                  {areReservationOptionsLoaded && isEmptyAccount && (
                    <div className="no-reservations">
                      <div className="info-text">
                        {t("mobile.home.nothing_to_show")}
                      </div>
                    </div>
                  )}
                </>
              </PullToRefresh>
            </div>
          ) : (
            <div className="big-loader">
              <Loader variant="fullScreen" />
            </div>
          )}
        </div>
      </div>
      <BottomNav />
    </SafeViewArea>
  )
}

export default Reservations
