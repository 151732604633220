import { Trans, useTranslation } from "react-i18next"

import { RedirectLink } from "../../../../components/advanced/RedirectLink"
import Breadcrumbs from "../../../../components/Breadcrumbs"
import Intro from "../../../../components/Intro"
import Space from "../../../../components/Space"
import View from "../../../../components/View"

import { ReactComponent as DownloadSVG } from "../../../../assets/images/icons/Download.svg"
import ciscoImage from "../../../../assets/images/presence/cisco.png"
import jabraImage from "../../../../assets/images/presence/jabra.png"

import "./styles.sass"

type Integration = {
  name: string
  image: string
  cta: "jabra_kb" | "cisco_kb"
  kbKey: string
  download: string
}

const PresenceIntegration = () => {
  const { t } = useTranslation()

  const integrations: Integration[] = [
    {
      name: "Jabra",
      image: jabraImage,
      cta: "jabra_kb",
      kbKey: "jabra-kb",
      download: "jabra-download",
    },
    {
      name: "Cisco Webex",
      image: ciscoImage,
      cta: "cisco_kb",
      kbKey: "cisco-kb",
      download: "cisco-download",
    },
  ]

  return (
    <View className="PresenceIntegration">
      <Breadcrumbs
        depth={2}
        values={[
          t("desktop.settings.integrations.title"),
          t("desktop.settings.integrations.presence.title"),
        ]}
      />

      <Intro isConstrained>
        {t("desktop.settings.integrations.presence.intro")}
      </Intro>

      <Space size={0.75} />

      <div className="PresenceList">
        {integrations.map((integration) => (
          <div key={integration.name} className="PresenceCard">
            <h3>{integration.name}</h3>
            <RedirectLink
              className="download"
              to={integration.download}
              openInNewWindow={false}
            >
              <DownloadSVG />
            </RedirectLink>
            <RedirectLink className="PresenceImage" to={integration.kbKey}>
              <img src={integration.image} alt={integration.name} />
            </RedirectLink>
            <p>
              <Trans
                i18nKey={`desktop.settings.integrations.presence.${integration.cta}`}
              >
                <RedirectLink to={integration.kbKey}>Click here</RedirectLink>
              </Trans>
            </p>
          </div>
        ))}
      </div>
    </View>
  )
}

export default PresenceIntegration
