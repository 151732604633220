import React from "react"

import { t } from "i18next"

import {
  CUSTOM_CONTENT_TYPES,
  LAYOUT_GRID,
  LAYOUT_GRID_TYPES,
  SUPPORTED_TYPES,
} from "./constants"

import {
  GalleryContent,
  LayoutGridType,
  Section,
  SectionContent,
  SectionContentType,
  TextContent,
} from "../../../../redux/api/customContent/types"

import { ReactComponent as AddPhotoSVG } from "../../../../assets/images/icons/AddPhoto.svg"
import { ReactComponent as DashboardSVG } from "../../../../assets/images/icons/Dashboard.svg"
import { ReactComponent as InsertTextSVG } from "../../../../assets/images/icons/InsertText.svg"
import { ReactComponent as LayoutCrossSVG } from "../../../../assets/images/icons/LayoutCross.svg"
import { ReactComponent as LayoutFullSVG } from "../../../../assets/images/icons/LayoutFull.svg"
import { ReactComponent as LayoutHorizontalHalfSVG } from "../../../../assets/images/icons/LayoutHorizontalHalf.svg"
import { ReactComponent as LayoutHorizontalLowerThirdSVG } from "../../../../assets/images/icons/LayoutHorizontalLowerThird.svg"
import { ReactComponent as LayoutHorizontalUpperThirdSVG } from "../../../../assets/images/icons/LayoutHorizontalUpperThird.svg"
import { ReactComponent as LayoutVerticalHalfSVG } from "../../../../assets/images/icons/LayoutVerticalHalf.svg"
import { ReactComponent as LayoutVerticalLeftThirdSVG } from "../../../../assets/images/icons/LayoutVerticalLeftThird.svg"
import { ReactComponent as LayoutVerticalRightThirdSVG } from "../../../../assets/images/icons/LayoutVerticalRightThird.svg"
import { ReactComponent as WebPageSVG } from "../../../../assets/images/icons/WebPageArrow.svg"

export const ICONS = {
  [LAYOUT_GRID.FULL]: (props: { className?: string }) => (
    <LayoutFullSVG {...props} />
  ),
  [LAYOUT_GRID.HORIZONTAL_HALF]: (props: { className?: string }) => (
    <LayoutHorizontalHalfSVG {...props} />
  ),
  [LAYOUT_GRID.HORIZONTAL_HALF_MAIN_TOP]: (props: { className?: string }) => (
    <LayoutHorizontalUpperThirdSVG {...props} />
  ),
  [LAYOUT_GRID.HORIZONTAL_HALF_MAIN_BOTTOM]: (props: {
    className?: string
  }) => <LayoutHorizontalLowerThirdSVG {...props} />,
  [LAYOUT_GRID.VERTICAL_HALF]: (props: { className?: string }) => (
    <LayoutVerticalHalfSVG {...props} />
  ),
  [LAYOUT_GRID.VERTICAL_HALF_MAIN_LEFT]: (props: { className?: string }) => (
    <LayoutVerticalLeftThirdSVG {...props} />
  ),
  [LAYOUT_GRID.VERTICAL_HALF_MAIN_RIGHT]: (props: { className?: string }) => (
    <LayoutVerticalRightThirdSVG {...props} />
  ),
  [LAYOUT_GRID.QUARTER]: (props: { className?: string }) => (
    <LayoutCrossSVG {...props} />
  ),
} as const

export const findLayoutIndex = (value: number) => {
  if (!value) return 0

  let index = SUPPORTED_TYPES.findIndex((o) => o === value)

  return Math.max(0, index)
}

export const findIconLayoutType = (type: number): any => {
  if (type === LAYOUT_GRID_TYPES.full) return ICONS.full({})
  if (type === LAYOUT_GRID_TYPES.horizontal_half)
    return ICONS.horizontal_half({})
  if (type === LAYOUT_GRID_TYPES.horizontal_half_main_top)
    return ICONS.horizontal_half_main_top({})
  if (type === LAYOUT_GRID_TYPES.horizontal_half_main_bottom)
    return ICONS.horizontal_half_main_bottom({})
  if (type === LAYOUT_GRID_TYPES.vertical_half) return ICONS.vertical_half({})
  if (type === LAYOUT_GRID_TYPES.vertical_half_main_left)
    return ICONS.vertical_half_main_left({})
  if (type === LAYOUT_GRID_TYPES.vertical_half_main_right)
    return ICONS.vertical_half_main_right({})
  if (type === LAYOUT_GRID_TYPES.quarter) return ICONS.quarter({})

  console.warn(`Unsupported grid type ${type}.`)
  return "close"
}

export const ensureSections = (sections: Section[], count: number) => {
  const result = [...sections]

  while (result.length < count) {
    result.push({ type: null, content: null })
  }

  return result
}

export const getSectionCount = (type: LayoutGridType): number => {
  const sectionCounts: Record<LayoutGridType, number> = {
    [LAYOUT_GRID_TYPES.full]: 1,
    [LAYOUT_GRID_TYPES.horizontal_half]: 2,
    [LAYOUT_GRID_TYPES.horizontal_half_main_top]: 2,
    [LAYOUT_GRID_TYPES.horizontal_half_main_bottom]: 2,
    [LAYOUT_GRID_TYPES.vertical_half]: 2,
    [LAYOUT_GRID_TYPES.vertical_half_main_left]: 2,
    [LAYOUT_GRID_TYPES.vertical_half_main_right]: 2,
    [LAYOUT_GRID_TYPES.quarter]: 4,
  }

  if (type in sectionCounts) {
    return sectionCounts[type]
  } else {
    throw new Error(`Grid type ${type} is not supported`)
  }
}

export const getClassName = (type: LayoutGridType): string => {
  const classNames: Record<LayoutGridType, string> = {
    [LAYOUT_GRID_TYPES.full]: "grid-full",
    [LAYOUT_GRID_TYPES.horizontal_half]: "grid-horizontal-half",
    [LAYOUT_GRID_TYPES.horizontal_half_main_top]:
      "grid-horizontal-half grid-horizontal-half-main-top",
    [LAYOUT_GRID_TYPES.horizontal_half_main_bottom]:
      "grid-horizontal-half grid-horizontal-half-main-bottom",
    [LAYOUT_GRID_TYPES.vertical_half]: "grid-vertical-half",
    [LAYOUT_GRID_TYPES.vertical_half_main_left]:
      "grid-vertical-half grid-vertical-half-main-left",
    [LAYOUT_GRID_TYPES.vertical_half_main_right]:
      "grid-vertical-half grid-vertical-half-main-right",
    [LAYOUT_GRID_TYPES.quarter]: "grid-quarter",
  }

  if (type in classNames) {
    return classNames[type]
  } else {
    console.warn(`Unsupported grid type ${type}.`)
    return `grid-unknown`
  }
}

export const getSectionContentTypeAsString = (
  type: SectionContentType,
): string => {
  const contentTypeMap: { [key in SectionContentType]: string } = {
    [CUSTOM_CONTENT_TYPES.gallery]: "gallery",
    [CUSTOM_CONTENT_TYPES.text]: "text",
    [CUSTOM_CONTENT_TYPES.webpage]: "webpage",
    [CUSTOM_CONTENT_TYPES.roomStatus]: "roomStatus",
  }

  return contentTypeMap[type] || "section-content-unknown"
}

export const asString = (type: number) => {
  if (type === LAYOUT_GRID_TYPES.full) return "FULL"
  if (type === LAYOUT_GRID_TYPES.horizontal_half) return "HORIZONTAL_HALF"
  if (type === LAYOUT_GRID_TYPES.horizontal_half_main_top) {
    return "HORIZONTAL_HALF_MAIN_TOP"
  }
  if (type === LAYOUT_GRID_TYPES.horizontal_half_main_bottom) {
    return "HORIZONTAL_HALF_MAIN_BOTTOM"
  }
  if (type === LAYOUT_GRID_TYPES.vertical_half) return "VERTICAL_HALF"
  if (type === LAYOUT_GRID_TYPES.vertical_half_main_left) {
    return "VERTICAL_HALF_MAIN_LEFT"
  }
  if (type === LAYOUT_GRID_TYPES.vertical_half_main_right) {
    return "VERTICAL_HALF_MAIN_RIGHT"
  }
  if (type === LAYOUT_GRID_TYPES.quarter) return "QUARTER"

  return type.toString()
}

export const CONTENT_TYPES_OPTIONS = [
  {
    title: t(
      "desktop.settings.rooms.custom_content.forms.content_type.content_types.gallery.title",
    ),
    description: t(
      "desktop.settings.rooms.custom_content.forms.content_type.content_types.gallery.description",
    ),
    icon: <AddPhotoSVG />,
    type: CUSTOM_CONTENT_TYPES.gallery,
  },
  {
    title: t(
      "desktop.settings.rooms.custom_content.forms.content_type.content_types.text.title",
    ),
    description: t(
      "desktop.settings.rooms.custom_content.forms.content_type.content_types.text.description",
    ),
    icon: <InsertTextSVG />,
    type: CUSTOM_CONTENT_TYPES.text,
  },
  {
    title: t(
      "desktop.settings.rooms.custom_content.forms.content_type.content_types.webpage.title",
    ),
    description: t(
      "desktop.settings.rooms.custom_content.forms.content_type.content_types.webpage.description",
    ),
    icon: <WebPageSVG />,
    type: CUSTOM_CONTENT_TYPES.webpage,
  },
  {
    title: t(
      "desktop.settings.rooms.custom_content.forms.content_type.content_types.room_status.title",
    ),
    description: t(
      "desktop.settings.rooms.custom_content.forms.content_type.content_types.room_status.description",
    ),
    icon: <DashboardSVG />,
    type: CUSTOM_CONTENT_TYPES.roomStatus,
  },
] as const

export const parseDaysOfWeek = (repeatString: string, matcher: RegExp) => {
  const match = repeatString.match(matcher)

  if (match) {
    const daysOfWeekString = match[1]

    return daysOfWeekString.split(",")
  }
  return []
}

function hasProperty<K extends string>(
  obj: unknown,
  key: K,
): obj is { [P in K]: unknown } {
  return typeof obj === "object" && obj !== null && key in obj
}

export const isTextContent = (
  content: SectionContent,
): content is TextContent => {
  return hasProperty(content, "text")
}

export const isGalleryContent = (
  content: SectionContent,
): content is GalleryContent => {
  return (
    hasProperty(content, "images") &&
    hasProperty(content, "interval") &&
    hasProperty(content, "selected")
  )
}
