import { useEffect } from "react"

import { useTranslation } from "react-i18next"

import { useNavigation } from "../../../../hooks/useNavigation"
import { useToast } from "../../../../hooks/useToast"
import { openBrowserWindow } from "../../../../utils"
import { INTEGRATIONS_PATHS } from "../constants"
import CalendarIntegratedCard from "./CalendarIntegratedCard"
import CalendarProviderCard from "./CalendarProviderCard"
import { CALENDAR_PROVIDERS, checkCalendarCompletion } from "./constants"

import {
  useConnectCalendarMutation,
  useFetchCalendarsQuery,
} from "../../../../redux/api/calendars"
import { CalendarType } from "../../../../redux/api/calendars/types"
import { useStartScanAndCheckRoomsMutation } from "../../../../redux/api/rooms"
import { isRejected } from "../../../../redux/api/types"

import Loader from "../../../../components/basic/Loader"
import Breadcrumbs from "../../../../components/Breadcrumbs"
import Intro from "../../../../components/Intro"
import Space from "../../../../components/Space"
import View from "../../../../components/View"

import "./styles.sass"

const CalendarProviders = () => {
  const { push } = useNavigation()
  const { errorToast } = useToast()
  const [connectCalendar] = useConnectCalendarMutation()

  const googleOffice365Handler = async (
    type: CalendarType.GOOGLE | CalendarType.OFFICE365,
  ) => {
    const response = await connectCalendar({
      type,
      next: `${window.location.origin}${INTEGRATIONS_PATHS.calendars}?scan=true`,
    })

    if (isRejected(response)) {
      errorToast(response.error.message)
      return
    }

    openBrowserWindow(response.data.url, "_self")
  }

  const handleGoogleClick = () => {
    googleOffice365Handler(CalendarType.GOOGLE)
  }

  const handleOffice365Click = () => {
    googleOffice365Handler(CalendarType.OFFICE365)
  }

  const handleExchangeClick = () => push(INTEGRATIONS_PATHS.exchange)

  const handleICalendarClick = () => push(INTEGRATIONS_PATHS.icalendar)

  const determineAction = (type: CalendarType) => {
    switch (type) {
      case CalendarType.GOOGLE:
        return handleGoogleClick
      case CalendarType.EXCHANGE:
        return handleExchangeClick
      case CalendarType.ICALENDAR:
        return handleICalendarClick
      case CalendarType.OFFICE365:
        return handleOffice365Click
      default:
        return handleGoogleClick
    }
  }

  const cards = []

  for (const [type, { title, icon, description, kbKey }] of Object.entries(
    CALENDAR_PROVIDERS,
  )) {
    cards.push(
      <CalendarProviderCard
        key={type}
        description={description}
        kbKey={kbKey}
        title={title}
        icon={icon}
        onClick={determineAction(type as CalendarType)}
      />,
    )
  }

  return <div className="Calendars__list">{cards}</div>
}

const Calendars = () => {
  const { t } = useTranslation()
  const { data: { results: calendars = [] } = {}, isLoading } =
    useFetchCalendarsQuery()

  const { searchParams, push } = useNavigation()
  const [scanRooms] = useStartScanAndCheckRoomsMutation()

  useEffect(() => {
    if (searchParams?.scan) {
      scanRooms()
      push(INTEGRATIONS_PATHS.calendars)
    }
  }, [scanRooms, searchParams])

  const hasCalendar =
    calendars.length > 0 && checkCalendarCompletion(calendars[0])

  const calendar = calendars[0]

  return (
    <View className="Calendars">
      <Breadcrumbs
        depth={2}
        values={[
          t("desktop.settings.integrations.title"),
          t("desktop.settings.integrations.calendars.title"),
        ]}
      />

      <Intro isConstrained>
        {t("desktop.settings.integrations.calendars.intro")}
      </Intro>

      <Space size={0.75} />

      {isLoading && <Loader className="loader" />}

      {!isLoading && hasCalendar && (
        <CalendarIntegratedCard calendar={calendar} />
      )}

      {!isLoading && !hasCalendar && <CalendarProviders />}
    </View>
  )
}

export default Calendars
